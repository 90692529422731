import React from 'react';

  const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '10px',
  border: '1px solid black',
  borderRadius: '10px',
  cursor: 'pointer',
  backgroundColor: 'green',
  color: 'white'
}

export default function GiftButton({giftedBy, userId, itemId, onClick}) {

    function click() {
        if(giftedBy === userId){
            onClick("", itemId)
        } else if(giftedBy === undefined || giftedBy === ""){
            onClick(userId, itemId)
        }
    }

  return (
    <div style={buttonStyle} onClick={() => click()}>
      {giftedBy===undefined || giftedBy===""?
            <div>Offrir</div>
        :
        giftedBy === userId ?
            <div>Ne plus offrir</div>
        :
        <div>Déjà offert</div>
      }
    </div>
  );
}