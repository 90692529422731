import React from 'react';
import GiftButton from './giftButton';

const imgStyle = {
    maxWidth: '200px',
    width: '50%',
    height:'50%'
}

const containerStyle = {
    display: 'flex',
    margin: '0px 0px 20px 0px'
}

const container2Style = {
    margin: '15px',
    padding: '15px',
    maxWidth:'700px',
    border: '1px solid black'
}

const rightContainerStyle = {
    width: '50%',
    maxWidth: '400px',
    margin: '0 20px 0 20px'
}

const containerDivStyle = {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%'
}

const titleStyle = {
    fontWeight: 'bold'
}

const descriptionStyle = {
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontStyle: 'italic'
}

export default function Item({data, userData, offerButton, hide = false}) {

  return (
    <div style={containerDivStyle}>
        <div style={container2Style}>
            <div style={containerStyle}>
                <img src={data.imageUrl} style={imgStyle}/>
                <div style={rightContainerStyle}>
                    <div style={titleStyle}>{data.name}</div>
                    <br/>
                    <div style={descriptionStyle}>{data.description}</div>
                    <br/>
                    <a href={data.link} target='_blank'>{data.linkName}</a>
                </div>
            </div>
            {hide===false?
                <GiftButton giftedBy={data.giftedBy} userId={userData} itemId={data.id} onClick={offerButton.bind()}/>
                :
                <p></p>
            }
        </div>
    </div>
  );
}