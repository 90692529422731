import logo from './logo.svg';
import './App.css';
import Home from './page/home.js';
import Modal from './component/modal.js';
import React from 'react';
import CryptoJS from 'crypto-js';

import {findAll, updateItem, createPassword} from './services/firestoreHelper.js'

class App extends React.Component {
  state = {
    itemList: [],
    userList: [],
    connected: "",
    userId: "",
    isModalOpen: true
  }

  componentDidMount() {

    const fetchData = async () => {
      const user = await findAll("user")
      this.state.userList = user
    }

    fetchData()
  }

  fetchItems() {
    const fetch = async () => {
      const items = await findAll("item")
      this.setState({itemList: items})
    }

    fetch()
  }

  checkUser(pseudo, password) {
    var hashPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    return this.state.userList.find(it => it.pseudo.toLowerCase() === pseudo.toLowerCase() && it.password === hashPassword) !== undefined
  }

  checkConnexion(pseudo, password) {
    if(pseudo==="admin" && password==="pa$$word") {
      this.setState({connected: "admin"})
      this.fetchItems()
    } else if(this.checkUser(pseudo, password)) {
      console.log(pseudo.toLowerCase())
      this.setState({userId: this.state.userList.find(it => it.pseudo.toLowerCase() === pseudo.toLowerCase()).id})
      this.setState({connected: "user"})
      this.fetchItems()
    } else if(this.state.userList.find(it => it.pseudo.toLowerCase() === pseudo.toLowerCase() && it.password === undefined) !== undefined){
      var userPos = this.state.userList.findIndex(it => it.pseudo.toLowerCase() === pseudo.toLowerCase() && it.password === undefined)
      var user = this.state.userList[userPos]
      user.password = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
      createPassword(user.id, user)
      this.setState({userId: user.id})
      this.setState({connected: "user"})
      this.fetchItems()
    } else {
      this.setState({connected: ""})
    }
  }

  getDataForScreen() {
    return {
      userId: this.state.userId,
      connected: this.state.connected,
      items: this.state.itemList
    }
  }

  offerButton(userId, itemId) {
    console.log(userId)
    var tempList = this.state.itemList
    var posItem = tempList.findIndex(elem => elem.id === itemId);
    var item = tempList[posItem]

    item.giftedBy = userId

    tempList[posItem] = item

    this.setState({itemList: tempList})

    updateItem(itemId, item)
  }

  closeModal() {
    this.setState({isModalOpen: false})
  }

  render() {
    return (
      <div>
        <Home connexion={this.checkConnexion.bind(this)} data={this.getDataForScreen()} offerButton={this.offerButton.bind(this)}/>
        {this.state.isModalOpen ? <Modal closeModal={this.closeModal.bind(this)}/> : console.log("close")}
      </div>
    );
  }
}

export default App;
